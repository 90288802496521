import React from "react";
import { Arrow } from "../../../constants/Icons";
import VanillaTilt from "vanilla-tilt";
import "./index.scss";
import { useNavigate } from "react-router-dom";
const CardCourse = ({ item }) => {
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate(`courses/${item?.link}`);
    setTimeout(() => {
      return (window.location.href = "#filter");
    }, 0);
  };
  VanillaTilt.init(document.querySelectorAll(".card-course"), {
    max: 25,
    speed: 400,
    glare: true,
    "max-glare": 0.3,
  });
  return (
    <div className="card-course" onClick={onSubmit}>
      <div>
        <h4
          style={{
            fontSize:
              item?.title.split("").length > 17 ||
              item?.title === "Введение в IT"
                ? "37px"
                : "46px",
          }}
        >
          {item?.title}
        </h4>
        <p>{item?.subtitle}</p>
      </div>
      <div className="course-icons">
        {item?.icon}
        <Arrow stroke="white" />
      </div>
    </div>
  );
};

export default CardCourse;
