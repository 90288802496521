import { Dropdown, Menu, Space } from "antd";
import uz from "../../assets/icons/uzb.svg";
import ru from "../../assets/icons/rus.svg";
import en from "../../assets/icons/eng.svg";
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import "./index.scss";
const DropDown = () => {
  const lang = localStorage.getItem("language");
  const [open, setOpen] = useState(false);
  const changeLanguage = (e) => {
    localStorage.setItem("language", e.key);
    window.location.reload();
  };
  const handleVisibleChange = (flag) => {
    setOpen(flag);
  };
  const userMenu = (
    <Menu className="menu">
      <Menu.Item
        key={
          lang === "uz"
            ? "ru"
            : lang === "ru"
            ? "uz"
            : lang === "en"
            ? "uz"
            : ""
        }
        className="menuItem"
        onClick={(e) => changeLanguage(e)}
      >
        <img
          src={
            lang === "uz" ? ru : lang === "ru" ? uz : lang === "en" ? uz : ""
          }
          alt=""
        />
        {lang === "uz"
          ? "Рус"
          : lang === "ru"
          ? "O'zb"
          : lang === "en"
          ? "O'zb"
          : ""}
      </Menu.Item>
      <Menu.Item
        key={
          lang === "uz"
            ? "en"
            : lang === "ru"
            ? "en"
            : lang === "en"
            ? "ru"
            : ""
        }
        className="menuItem"
        onClick={(e) => changeLanguage(e)}
      >
        <img
          src={
            lang === "uz" ? en : lang === "ru" ? en : lang === "en" ? ru : ""
          }
          alt=""
        />
        {lang === "uz"
          ? "Eng"
          : lang === "ru"
          ? "Eng"
          : lang === "en"
          ? "Рус"
          : ""}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={userMenu}
      trigger={["click"]}
      className={"dropdown"}
      onClick={() => setOpen((prev) => !prev)}
      open={open}
      onOpenChange={handleVisibleChange}
    >
      <Space className="space">
        {lang
          ? lang === "uz"
            ? "O'zb"
            : lang === "ru"
            ? "Рус"
            : lang === "en"
            ? "Eng"
            : ""
          : "Рус"}
        <BsChevronDown
          style={{ rotate: open && "180deg", transition: "0.5s" }}
        />
      </Space>
    </Dropdown>
  );
};
export default DropDown;
