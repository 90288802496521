import React from "react";
import "./index.scss";
import { HiLocationMarker, HiOutlineLocationMarker } from "react-icons/hi";
import { Academ, Briefcase, Export } from "../../../constants/Icons";
import { Rate } from "antd";
import { Language } from "../../../services/language";
const CardUniver = ({ data, language, type }) => {
  return (
    <div className="card-univer">
      <h4>{data?.title[language]}</h4>
      {type === "right" ? (
        <div className="card-body">
          <div className="card-logo">
            <div className="card-img-box">
              <img src={data?.logo} alt="" />
            </div>
            {/* <p className="card-logo-title">
              "IT-PARK UNIVERSITY" mas’uliyati cheklangan jamiyati
            </p> */}
            <div className="card-btns">
              <button>{data?.mode[language]}</button>
              <button>
                <a target={"_blank"} href={data?.location}>
                  <HiOutlineLocationMarker size={20} />
                  {data?.title[language]}
                </a>
              </button>
            </div>
          </div>
          <div className="card-title">
            <div className="card-about">
              <Academ />
              <div>
                <p>
                  {data?.graduates} {Language("graduates")}
                </p>{" "}
                {data?.year ? (
                  <span>
                    {Language("inYear")} (2025{language === "ru" ? "г" : "y"}.){" "}
                  </span>
                ) : (
                  <span>{Language("inYear")} </span>
                )}
              </div>
            </div>
            <div className="card-about">
              <Briefcase />
              <div>
                <p>
                  {data?.incoming} {Language("applicant")}
                </p>{" "}
                <span>{Language("inYear")}</span>
              </div>
            </div>
            <div className="flex-center-gap">
              <button>
                <Export />
                <a target={"_blank"} href={data?.link[language]}>
                  {data?.linkName}
                </a>
              </button>
            </div>
          </div>

          <div className="card-background">
            <img src={data?.background} alt="" />
          </div>

          <div className="card-background-shadow"></div>
          <div className="card-background-shadow2"></div>
        </div>
      ) : (
        <div className="card-body-2">
          <div className="card-logo">
            <div className="card-img-box">
              <img src={data?.logo} alt="" />
            </div>
            {/* <p className="card-logo-title">
              "IT-PARK UNIVERSITY" mas’uliyati cheklangan jamiyati
            </p> */}
            <div className="card-btns">
              <button>{data?.mode[language]}</button>
              <button>
                <a target={"_blank"} href={data?.location}>
                  <HiOutlineLocationMarker size={20} />
                  {data?.title[language]}
                </a>
              </button>
            </div>
          </div>
          <div className="card-title">
            <div className="card-about">
              <Academ />
              <div>
                <p>
                  {data?.graduates} {Language("graduates")}
                </p>{" "}
                <span>{Language("inYear")}</span>
              </div>
            </div>
            <div className="card-about">
              <Briefcase />
              <div>
                <p>
                  {data?.incoming} {Language("applicant")}
                </p>{" "}
                <span>{Language("inYear")}</span>
              </div>
            </div>
            {/* <h5>Перейти на сайт</h5> */}
            <div className="flex-center-gap">
              <button>
                <Export />
                <a target={"_blank"} href={data?.link[language]}>
                  {data?.linkName}
                </a>
              </button>
            </div>
          </div>

          <div className="card-background">
            <img src={data?.background} alt="" />
          </div>

          <div className="card-background-shadow"></div>
          <div className="card-background-shadow2"></div>
        </div>
      )}
    </div>
  );
};

export default CardUniver;
