import React, { useState } from "react";
import "./index.scss";
import { VscAdd, VscClose } from "react-icons/vsc";
import { Language } from "../../../services/language";
import { Collapse } from "antd";
const { Panel } = Collapse;
const CardQuestion = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="card-question-container">
      <div className="card-box">
        <div className="card-question">
          <span>{data?.id}.</span>
          <div className="card-title">
            <h6>{data?.question}</h6>
            {/* <p onClick={() => setOpen(!open)}>{Language("see_answer")}</p> */}
          </div>
        </div>
        <Collapse accordion activeKey={open && "1"}>
          <Panel key="1" showArrow={false}>
            <div className="card-answer">
              <div className="card-title">
                <h6>{data?.answer}</h6>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="card-action" onClick={() => setOpen(!open)}>
        {open ? (
          <VscClose size={40} className="card-svgClose" />
        ) : (
          <VscAdd size={40} className="card-svg" />
        )}
      </div>
    </div>
  );
};

export default CardQuestion;
