
export const filterByDuration = (data, value) => {
  if (value === "3") {
    return data.filter(
      (item) =>
        item.duration === "1" ||
        item.duration === "2"
    );
  }
  if (value === "3-6") {
    return data.filter(
      (item) =>
        item.duration === "3" ||
        item.duration === "4" ||
        item.duration === "5" 
    );
  }
  if (value === "6-12") {
    return data.filter(
      (item) =>
        item.duration === "6" ||
        item.duration === "7" ||
        item.duration === "8" ||
        item.duration === "9" ||
        item.duration === "10" ||
        item.duration === "11" ||
        item.duration === "12"
    );
  }
  return data;
};
