import React from "react";
import { Select } from "antd";
import "./index.scss";
import { Language } from "../../../services/language";
import { alphabetFunc } from "../../../services/alphabetFunc";
const { Option } = Select;
const FilterBox = ({ data, cityId, alphabet, setPageSize, setPageIndex }) => {
  const location = localStorage.getItem("location");
  const language = localStorage.getItem("language");
  const handleChangeSelect = (value) => {
    setPageSize(10);
    setPageIndex(0);
    cityId(value);
  };
  const handleChangeAlphabetSelect = (value) => {
    alphabet(value);
  };
  const cityData = [
    {
      name: Language("cities")["all"],
      key: "all",
    },
    {
      name: Language("cities")["tashkent"],
      key: "tashkent",
    },
    {
      name: Language("cities")["tashkent-region"],
      key: "tashkent-region",
    },
    {
      name: Language("cities")["bukhara"],
      key: "bukhara",
    },
    {
      name: Language("cities")["fergana"],
      key: "fergana",
    },
    {
      name: Language("cities")["khorezm"],
      key: "khorezm",
    },
    {
      name: Language("cities")["andijon"],
      key: "andijon",
    },
    {
      name: Language("cities")["jizzax"],
      key: "jizzax",
    },
    {
      name: Language("cities")["kashkadaryo"],
      key: "kashkadaryo",
    },
    {
      name: Language("cities")["samarkand"],
      key: "samarkand",
    },
    {
      name: Language("cities")["namangan"],
      key: "namangan",
    },
    {
      name: Language("cities")["navoi"],
      key: "navoi",
    },
    {
      name: Language("cities")["sirdaryo"],
      key: "sirdaryo",
    },
    {
      name: Language("cities")["surxondaryo"],
      key: "surxondaryo",
    },
    {
      name: Language("cities")["karakalpok"],
      key: "karakalpok",
    },
  ];
  return (
    <div className="centers-filter-component">
      <Select
        defaultValue={
          location === null ? "Город" : Language("cities")[location]
        }
        className="selectInput"
        onChange={handleChangeSelect}
        style={{ width: "251px" }}
      >
        {cityData.map((item) => (
          <Option value={item.key} key={item.key}>
            {item.name}
          </Option>
        ))}
      </Select>
      <Select
        defaultValue={Language("alphabetically")}
        className="selectInput"
        onChange={handleChangeAlphabetSelect}
        style={{ width: "280px" }}
      >
        <Option value={"null"}>{Language("all")}</Option>
        <Option value={"from"}>{Language("alphabetically-item-from")}</Option>
        <Option value={"to"}>{Language("alphabetically-item-before")}</Option>
      </Select>
    </div>
  );
};

export default FilterBox;
