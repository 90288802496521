import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import FilterBox from "./_components/FilterBox";
import CardCenters from "../../components/Cards/CardCenters/CardCenters";
import axios from "axios";
import CardUniver from "../../components/Cards/CardUniver/CardUniver";
import { useNavigate, useParams } from "react-router-dom";
import { Language } from "../../services/language";
import { GlobalContex } from "../../store/Context";
import Download from "../../components/Download/Download";
import { dataUniver } from "./helpers";
import { Pagination } from "antd";
import { centersData } from "./data";
import { soato } from "../../services/soato";

const Centers = () => {
  const param = useParams();
  const location = localStorage.getItem("location");
  const language = localStorage.getItem("language");
  const [open, setOpen] = useState(param?.id === "centers" ? true : false);
  const [cityId, setCityId] = useState(location);
  const [alphabet, setAlphabet] = useState(null);
  const { getData } = useContext(GlobalContex);
  const navigate = useNavigate();
  const changeTab = (param) => {
    navigate(`/educations/${param}`);
  };
  var filterData =
    cityId === null || cityId === "all"
      ? getData?.data?.data
      : getData?.data?.data?.filter(
          (item) => String(item?.region?.soato) === soato(cityId)
        );

  // var filterData =
  //   cityId === null || cityId === "all"
  //     ? centersData
  //     : centersData?.filter((item) => item.city === cityId);
  if (alphabet === "from") {
    filterData?.sort((a, b) => a.company_name?.localeCompare(b.company_name));
  }
  if (alphabet === "to") {
    filterData?.sort((a, b) => b.company_name?.localeCompare(a.company_name));
  }
  // PAGINATION; //
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const handlePagination = (page) => {
    setPageSize(Number(page + "0"));
    setPageIndex(Number(page + "0") - 10);
    window.location.href = "#centers";
  };
  return (
    <section className="centers-container wrapper">
      <div className="centers-header" id="centers">
        <div
          className="centers-title"
          style={{
            width:
              language === "ru"
                ? "600px"
                : language === "uz"
                ? "500px"
                : "550px",
          }}
        >
          <h4
            onClick={() => {
              changeTab("universities");
              setOpen(false);
            }}
          >
            {Language("it_courses")}
          </h4>
          <h4
            onClick={() => {
              changeTab("centers");
              setCityId(location);
              setAlphabet(null);
              setOpen(true);
            }}
          >
            {Language("it_universities")}
          </h4>
          <span
            className={`centers-bg-title ${
              param.id === "centers"
                ? language === "ru"
                  ? "title-ru"
                  : language === "uz"
                  ? "title-uz"
                  : language === "en"
                  ? "title-en"
                  : "title-ru"
                : language === "ru"
                ? "titlee-ru"
                : language === "uz"
                ? "titlee-uz"
                : language === "en"
                ? "titlee-en"
                : "titlee-ru"
            }`}
          ></span>
        </div>

        <div
          className={`center-filter`}
          style={{ backgroundColor: param.id !== "centers" && "transparent" }}
          onClick={() => {
            setOpen(!open);
            setCityId(location);
            setAlphabet(null);
          }}
        >
          {param?.id === "centers" && (
            <div className="centers-filter-icon">
              <Filter />
            </div>
          )}
        </div>
      </div>
      <div className="centers-header-responsive" id="centers">
        <div
          className="centers-title"
          // style={{
          //   width:
          //     language === "ru"
          //       ? "600px"
          //       : language === "uz"
          //       ? "500px"
          //       : "550px",
          // }}
        >
          <h4
            onClick={() => {
              changeTab("universities");
              setOpen(false);
            }}
            className={!open && "active"}
          >
            {Language("it_courses")}
          </h4>
          <h4
            onClick={() => {
              changeTab("centers");
              setCityId(location);
              setAlphabet(null);
              setOpen(true);
            }}
            className={open && "active"}
          >
            {Language("it_universities")}
          </h4>
          <span className={`centers-bg-title`}></span>
        </div>
      </div>
      <div className="download" hidden={param?.id !== "centers" ? true : false}>
        <Download />
      </div>
      {open && (
        <div className="center-filter-inputs">
          <FilterBox
            data={getData?.data}
            cityId={setCityId}
            alphabet={setAlphabet}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
          />
        </div>
      )}
      <div hidden={param?.id === "centers" ? true : false}>
        <div className="centers-body">
          {dataUniver?.map((item, index) => (
            <CardUniver
              data={item}
              key={item?.id}
              language={language}
              type={index % 2 === 0 ? "right" : "left"}
            />
          ))}
        </div>
      </div>

      <div hidden={param?.id !== "centers" ? true : false}>
        <div className="centers-body">
          {filterData
            ?.filter((_, index) => pageIndex <= index && index < pageSize)
            .map((item, index) => (
              <CardCenters
                data={item}
                key={item?.id}
                language={language}
                type={index % 2 === 0 ? "right" : "left"}
              />
            ))}
        </div>
      </div>
      {param.id === "centers" && filterData?.length > 10 && (
        <Pagination
          defaultCurrent={1}
          total={filterData?.length}
          pageSize={10}
          onChange={handlePagination}
          className="pagination"
        />
      )}
    </section>
  );
};

export default Centers;
