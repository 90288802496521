import React from "react";
import { Language } from "../../services/language";
import CardQuestion from "../Cards/CardQuestions/CardQuestion";
import "./index.scss";
const Questions = () => {
  const questionsData = require("../../assets/locale/question.json");
  const lang = localStorage.getItem("language");
  const data = questionsData.filter((item) => item.lang === lang);

  return (
    <div className="questions">
      <h4>{Language("answers_on_questions")}</h4>
      <div className="question-box">
        <div className="question-box-left">
          {data
            .filter((_, index) => index < data.length / 2)
            .map((item, index) => (
              <CardQuestion data={item} key={index} />
            ))}
        </div>
        <div className="question-box-right">
          {data
            .filter((_, index) => index >= data.length / 2)
            .map((item, index) => (
              <CardQuestion data={item} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
