import React from "react";
import { Language } from "../../services/language";
import "./index.scss";
const Download = () => {
  return (
    <a href="/PDF.pdf" download={"Образовательные_резиденты_IT_Park_Uzbekistan.pdf"} className={"download"}>
      {Language("download")}
    </a>
  );
};

export default Download;
