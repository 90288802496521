import React, { useContext } from "react";
import FilterData from "./_components/FilterData";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { useState } from "react";
import CardCourses from "../../components/Cards/CardCourses/CardCourses";
import { useParams } from "react-router-dom";
import { Empty, Pagination } from "antd";
import { Language } from "../../services/language";
import { GlobalContex } from "../../store/Context";
import { filterByDuration } from "../../services/maskLifeTime";
import "./index.scss";
import { soato } from "../../services/soato";
const Courses = () => {
  const location = localStorage.getItem("location");
  const language = localStorage.getItem("language");
  const {
    getData: allData,
    searchdata,
    currentLocation,
  } = useContext(GlobalContex);
  const param = useParams();

  const [filterInputs, setFilterInputs] = useState(true);
  const [cityId, setCityId] = useState(
    currentLocation === null ? soato(location) : currentLocation
  );

  const [alphabet, setAlphabet] = useState(null);
  const [price, setPrice] = useState(null);
  const [mode, setMode] = useState(null);
  const [lifeTime, setLifeTime] = useState(null);
  const [hashName, setHashName] = useState(null);
  const searchData = searchdata?.toLowerCase();
  let lenghtData = [];
  let globalSearchData = [];
  var filterData = allData?.data?.data;

  let ADDRESS =
    cityId === null || cityId === "all"
      ? filterData
      : filterData?.filter((item) => String(item?.region?.soato) === cityId);

  ADDRESS?.map((item) =>
    item.courses
      ?.filter((item) => item?.name?.length < 25)
      .map((item2) => {
        let newObj = item2;
        newObj["id"] = item?.company_id;
        lenghtData?.push(newObj);
      })
  );

  if (searchData?.length > 0) {
    lenghtData
      ?.filter((elem) => elem?.name?.toLowerCase().includes(searchData))
      .map((item) => globalSearchData?.push(item));
  }
  // if (searchData?.length > 0) {
  //   lenghtData
  //     ?.filter((elem) => elem?.direction.includes(searchData))
  //     .map((item) => globalSearchData?.push(item));
  // }
  if (searchData?.length > 0) {
    ADDRESS?.filter((elem) =>
      elem?.company_name
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9]+/g, "-")
        .includes(searchData.replace(/[^a-zA-Z0-9]+/g, "-"))
    ).map((item1) =>
      item1.courses?.map((elem) => {
        let newObj = elem;
        newObj["id"] = item1?.company_id;
        globalSearchData?.push(newObj);
      })
    );
  }
  // const arrUniq = [...new Map(globalSearchData.map((v) => [v.id, v])).values()];
  lenghtData = searchData?.length > 0 ? globalSearchData : lenghtData;

  if (alphabet === "from") {
    lenghtData?.sort((a, b) => a?.name?.localeCompare(b?.name));
  }
  if (alphabet === "to") {
    lenghtData?.sort((a, b) => b?.name?.localeCompare(a?.name));
  }
  if (price === "min") {
    lenghtData.sort((a, b) => a?.price - b?.price);
  }
  if (price === "max") {
    lenghtData.sort((a, b) => b?.price - a?.price);
  }
  lenghtData =
    mode === null || mode === "null"
      ? lenghtData
      : lenghtData.filter((item) => item?.method === mode);

  lenghtData =
    lifeTime === null ? lenghtData : filterByDuration(lenghtData, lifeTime);
  lenghtData =
    param.id === "all"
      ? lenghtData
      : lenghtData?.filter((item) =>
          item?.direction?.split(" ").join("").toLowerCase().includes(param.id)
        );

  const [pagination, setPagination] = useState({
    minValue: 0,
    maxValue: 9,
  });
  const handlePagination = (value) => {
    setPagination({
      minValue: (value - 1) * 9,
      maxValue: value * 9,
    });
    window.location.href = "#filter";
  };
  // lenghtData =
  //   hashName === null
  //     ? lenghtData
  //     : lenghtData.filter((item) =>
  //         item?.skills?.toLowerCase().includes(hashName.toLowerCase())
  //       );

  return (
    <section className="wrapper courses-container">
      <div className="courses-header" id="filter">
        <div className="flex-center">
          <h4>{Language("courses")}</h4>
          <div
            className="courses-filter-icon"
            onClick={() => setFilterInputs((ev) => !ev)}
          >
            <Filter />
          </div>
        </div>
        {filterInputs && (
          <div>
            <FilterData
              data={allData?.data}
              cityId={setCityId}
              alphabet={setAlphabet}
              language={language}
              price={setPrice}
              mode={setMode}
              lifeTime={setLifeTime}
              param={param?.id}
              hash={setHashName}
            />
          </div>
        )}
      </div>
      <div className="courses-body">
        <>
          {lenghtData
            ?.slice(pagination.minValue, pagination.maxValue)
            .map((item, index) => (
              <CardCourses
                data={item}
                language={language}
                filterData={filterData}
                setHashName={setHashName}
                key={index}
              />
            ))}
        </>
      </div>
      {lenghtData?.length === 0 && (
        <h3 className="notData">
          <Empty description={"Информация не найдена"} />
        </h3>
      )}
      {lenghtData.length > 0 && (
        <Pagination
          defaultCurrent={1}
          total={lenghtData.length}
          // pageSize={12}
          onChange={handlePagination}
          className="pagination"
        />
      )}
    </section>
  );
};

export default Courses;
