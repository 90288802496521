import React from "react";
import { useNavigate } from "react-router-dom";
import { BookCourse } from "../../constants/Icons";
import { Language } from "../../services/language";
import CardCourse from "../Cards/CardCourse/CardCourse";
import { data } from "./helper";
import "./index.scss";
const Course = () => {
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate(`courses/all`);
    setTimeout(() => {
      window.location.href = "#filter";
    }, 0);
  };
  return (
    <div className="course-wrapper">
      <div className="wrapper">
        <div className="course-header">
          <h6>{Language("courses")}</h6>
          <button onClick={onSubmit}>
            <BookCourse />
            {Language("all-courses")}
          </button>
        </div>
        <div className="course-box">
          {data.map((item) => (
            <CardCourse key={item.title} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Course;
