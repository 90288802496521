import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropDown from "../../DropDown/DropDown";

import { FiMoon, FiSun } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { CgMenuRightAlt } from "react-icons/cg";
import { FiSearch } from "react-icons/fi";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as Dark } from "../../../assets/icons/dark-logo.svg";
import "./index.scss";
import { Language } from "../../../services/language";
import Animation from "../../Animation/Animation";
import Modal from "../../Modal/Modal";
import SearchInput from "../../SearchInput/SearchInput";
import Toggle from "../../Toggle/Toggle";
import { GlobalContex } from "../../../store/Context";
import { maskLocation } from "../../../services/maskLocation";
import { ItCenter } from "../../../constants/Icons";
import Menu from "../../Menu/Menu";
import Menu2 from "../../Menu2/Menu2";

const Header = () => {
  const [open, setOpen] = useState(false);
  const location = localStorage.getItem("location");
  const navigate = useNavigate();
  const loc = useLocation();
  const { theme, toggleTheme } = useContext(GlobalContex);

  const onSubmit = (e) => {
    let id = e.target.id;
    if (loc.pathname === "/") {
      return (window.location.href = id);
    } else {
      navigate("/");
      setTimeout(() => {
        return (window.location.href = id);
      }, 0);
    }
  };

  return (
    <header className="">
      <Animation />
      <div className="wrapper header-nav" id="header-nav">
        <Link to={"/"}>{theme === "light" ? <Dark /> : <Logo />}</Link>
        <nav>
          <ul>
            <li onClick={onSubmit} id="#it-coures">
              {Language("courses")}
            </li>
            <li onClick={onSubmit} id="#region-contacts">
              {Language("contacts_by_region")}
            </li>
            <li onClick={onSubmit} id="#useful-links">
              {Language("useful_links")}
            </li>
            <li onClick={onSubmit} id="#faq">
              F.A.Q.
            </li>
          </ul>
        </nav>
        <div className="header-actions">
          <div>
            <SearchInput />
          </div>
          <div className="item">
            {location && (
              <div
                className="flex-center"
                onClick={() => setOpen((prev) => !prev)}
              >
                <HiOutlineLocationMarker size={20} className="location" />
                <p>{maskLocation(location)}</p>
              </div>
            )}
          </div>
          <div className="item">
            <DropDown />
          </div>
          <div>
            <Toggle />
          </div>
          <div className="menu-icon">
            {theme === "light" ? <Menu /> : <Menu2 />}
          </div>
        </div>
      </div>
      {/* {loc.pathname !== "/" && (
        <div className="main-title-itpark">Powered by IT Park</div>
      )} */}
      <div className="main-title">
        <ItCenter />
      </div>
      {(location === null || open) && <Modal setOpen={setOpen} />}
    </header>
  );
};

export default Header;
