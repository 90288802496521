import axios from "axios";
import { useEffect } from "react";
import { createContext, useState } from "react";

export const GlobalContex = createContext();

const Contex = ({ children }) => {
  // Theme Mode
  const mode = localStorage.getItem("mode");
  const location = localStorage.getItem("location");
  const [theme, setTheme] = useState(mode);
  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };
  // Get all courses data
  const [getData, setGetData] = useState([]);
  const getAllCourses = async () => {
    try {
      // const respon = await axios.get(
      //   "https://eduadmin.it-park.uz/api/itacademies"
      // );
      const respon = await axios.get(
        "https://my.it-park.uz/api/api/v1/quarterly-reports/getCourses?year=2023"
      );
      setGetData(respon);
    } catch (error) {
      console.log(error);
    }
  };
  // Search
  const [searchdata, setSearchData] = useState(null);
  // onClick Location
  const [currentLocation, setCurrentLocation] = useState(null);
  //onClick Map
  const [currentMapLocation, setCurrentMapLocation] = useState(location);
  useEffect(() => {
    getAllCourses();
  }, []);
  const state = {
    theme,
    toggleTheme,
    getData,
    searchdata,
    setSearchData,
    currentLocation,
    setCurrentLocation,
    currentMapLocation,
    setCurrentMapLocation,
  };

  return (
    <>
      <GlobalContex.Provider value={state}>{children}</GlobalContex.Provider>
    </>
  );
};
export default Contex;
