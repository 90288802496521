import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Course from "../../components/Course/Course";
import Map from "../../components/Map/Map";
import OurProjects from "../../components/OurProjects/OurProjects";
import Questions from "../../components/Questions/Questions";
import { Academ, BookNew, Builder, Hat, Notebook } from "../../constants/Icons";
import { Language } from "../../services/language";
import CountUp from "react-countup";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import "./index.scss";
import { useEffect } from "react";
import { useState } from "react";
import { GlobalContex } from "../../store/Context";
gsap.registerPlugin(ScrollTrigger);
const Home = () => {
  const [count, setCount] = useState(false);
  const { getData: allData, setSearchData } = useContext(GlobalContex);
  let data = [];
  var filterData = allData?.data?.data;
  filterData?.filter((item) => item.courses?.map((item) => data.push(item)));
  useEffect(() => {
    gsap.to("#homeBox", {
      scrollTrigger: {
        trigger: "#homeBox",
        onEnter: () => setCount(true),
      },
    });
    setSearchData(null);
  }, []);
  return (
    <section className="home-container">
      <div className="home-box wrapper">
        <Link to={"/educations/universities"} className="home-box-left">
          <h4>{Language("it_courses")}</h4>
          <div className="home-main-icon">
            <Hat />
          </div>
          <div className="home-icon-box" id="homeBox">
            <div className="flex-center-gap">
              <BookNew />
              <p>
                {count && (
                  <CountUp
                    start={0}
                    end={6}
                    duration={5}
                    delay={0}
                    separator=" "
                  />
                )}{" "}
                {Language("universities")}
              </p>
            </div>
            <div className="flex-center-gap">
              <Academ />
              <p>
                {count && (
                  <CountUp
                    start={0}
                    end={6346}
                    duration={5}
                    delay={0}
                    separator=" "
                  />
                )}{" "}
                {Language("graduates")}
              </p>
            </div>
          </div>
        </Link>
        <Link to={"/educations/centers"} className="home-box-right">
          <h4>{Language("it_universities")}</h4>
          <div className="home-main-icon">
            <Notebook />
          </div>
          <div className="home-icon-box" id="homeBox" >
            <div className="flex-center-gap">
              <Builder />
              <p>
                {count && (
                  <CountUp
                    start={0}
                    end={data?.length}
                    duration={5}
                    delay={0}
                    separator=" "
                  />
                )}{" "}
                {Language("course")}
              </p>
            </div>
            <div className="flex-center-gap">
              <Academ />
              <p>
                {count && (
                  <CountUp
                    start={0}
                    end={4713}
                    duration={5}
                    delay={0}
                    separator=" "
                  />
                )}{" "}
                {Language("graduates")}
              </p>
            </div>
          </div>
        </Link>
        <div className="home-box-bottom">
          <button>Powered by IT Park</button>
        </div>
      </div>
      <div id="it-coures">
        <Course />
      </div>
      <div className="home-map" id="region-contacts">
        <h4 className="home-title">{Language("contacts_by_region")}</h4>
        <div className="home-map-container">
          <div className="home-map-box">
            <Map />
          </div>
        </div>
      </div>
      <div className="home-our-projects" id="useful-links">
        <OurProjects />
      </div>
      <div className="home-questions wrapper" id="faq">
        <Questions />
      </div>
    </section>
  );
};

export default Home;
