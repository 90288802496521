export const maskNumber = (number) => {
  let num = number.replace(/[^0-9]/g, "");
  if (num.length === 12) {
    const ozg = num.split("");
    return `+${ozg[0]}${ozg[1]}${ozg[2]} ${ozg[3]}${ozg[4]} ${ozg[5]}${ozg[6]}${ozg[7]}-${ozg[8]}${ozg[9]}-${ozg[10]}${ozg[11]}`;
  }
  if (num.length === 9) {
    const ozg = num.split("");
    return `+998 ${ozg[0]}${ozg[1]} ${ozg[2]}${ozg[3]}${ozg[4]}-${ozg[5]}${ozg[6]}-${ozg[7]}${ozg[8]}`;
  }
  if (num.length < 6) {
    return null;
  }
  if (num.length > 12) {
    return null;
  }
  return num;
};
