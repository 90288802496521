import React from "react";
import "./index.scss";
import { ReactComponent as Logo } from "../../../assets/icons/logo-dark.svg";
import { ReactComponent as Telegram } from "../../../assets/icons/telegram.svg";
import { ReactComponent as Facebook } from "../../../assets/icons/facebook.svg";
import { ReactComponent as Youtube } from "../../../assets/icons/youtube.svg";
import { ReactComponent as Instagram } from "../../../assets/icons/instagram.svg";
import { Language } from "../../../services/language";
const Footer = () => {
  const lang = localStorage.getItem("language")
  return (
    <footer>
      <div className="footer-container wrapper">
        <div className="footer-box-top">
          <div className="footer-box-logo">
            <a href="https://it-park.uz/" target={"_blank"} rel="noreferrer">
              <Logo />
            </a>
            <p>
              <a
                href="https://www.google.com/maps/place/IT+Park/@41.3425428,69.337876,17z/data=!3m1!4b1!4m6!3m5!1s0x38aef5672b0eb1ed:0x676a59b4e6bf3c7e!8m2!3d41.3425428!4d69.337876!16s%2Fg%2F11h54h2yqv"
                target={"_blank"}
                rel="noreferrer"
              >
                {Language("footer")["address"]}
              </a>
            </p>
          </div>
          <div className="footer-box-titles">
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`https://it-park.uz/${lang}/itpark/about`}
            >
              {Language("footer")["about"]}
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://it-market.uz/job/"
            >
              {Language("footer")["careers"]}
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`https://it-park.uz/${lang}/itpark/news`}
            >
              {Language("footer")["news"]}
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`https://it-park.uz/${lang}/itpark/residents`}
            >
              {Language("footer")["residents"]}
            </a>

            {/* <a
              target={"_blank"}
              rel="noreferrer"
              href="https://it-park.uz/uz/itpark/shop"
            >
              {Language("footer")["shop"]}
            </a> */}

            {/* <a
              target={"_blank"}
              rel="noreferrer"
              href="https://it-park.uz/uz/itpark/news/residents"
            >
              {Language("footer")["residentsNews"]}
            </a> */}
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`https://it-park.uz/${lang}/itpark/infrastructure`}
            >
              {Language("footer")["infrastructure"]}
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`https://it-park.uz/${lang}/itpark/startups`}
            >
              {Language("footer")["startups"]}
            </a>
          </div>
          <div className="footer-box-actions">
            <a href="https://t.me/itpark_uz" target={"_blank"} rel="noreferrer">
              <Telegram id="st" />
            </a>
            <a
              href="https://www.instagram.com/itpark_uz/"
              target={"_blank"}
              rel="noreferrer"
            >
              <Instagram id="fil" />
            </a>
            <a
              href="https://www.facebook.com/itparkuzb/"
              target={"_blank"}
              rel="noreferrer"
            >
              <Facebook id="fil" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCZWqg4oxo2PyLGuQifUbeqg"
              target={"_blank"}
              rel="noreferrer"
            >
              <Youtube id="fil" />
            </a>
          </div>
        </div>
        <div className="footer-box-bottom">
          <p> {Language("footer")["weOnSocialNetworks"]}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
