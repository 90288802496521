import React, { useContext } from "react";
import { GlobalContex } from "../../store/Context";
import "./index.scss";
import { IoMdMoon } from "react-icons/io";
import { HiSun } from "react-icons/hi";
const Toggle = () => {
  const { theme, toggleTheme } = useContext(GlobalContex);

  const func = (e) => {
    if (theme === "dark") {
      localStorage.setItem("mode", "light");
    } else {
      localStorage.setItem("mode", "dark");
    }
    toggleTheme();
  };
  return (
    <div className="toggle" onClick={func}>
      <div className="circle"
      style={{transform: theme === "dark" ? "translateX(0)" : "translateX(28px)"}}
      ></div>
      <div className="sun">
        <HiSun />
      </div>
      <div className="moon">
        <IoMdMoon />
      </div>
    </div>
  );
};

export default Toggle;

{
  /* <div className="toggle-container">
      <div className="switch">
        <label htmlFor="toggle">
          <input
            id="toggle"
            className="toggle-switch"
            type="checkbox"
            checked={theme === "light" ? true : false}
            onChange={(e) => func(e)}
          />
          <div className="sun-moon">
            <div className="dots"></div>
          </div>
          <div className="background">
            <div className="stars1"></div>
            <div className="stars2"></div>
          </div>
          <div className="fill"></div>
        </label>
      </div>
    </div> */
}

// <div className="toggle-container">
//   <div className="switch">
//     <label htmlFor="toggle">
//       <input
//         id="toggle"
//         className="toggle-switch"
//         type="checkbox"
//         checked={theme === "light" ? true : false}
//         onChange={(e) => func(e)}
//       />
//       <div className="sun-moon">
//         <div className="dots">
//           <HiSun/>
//         </div>
//       </div>
//       <div className="background">
//         {/* {/* <div className="stars1"></div> */}
//         <div className="stars2">
//         <IoMdMoon/>
//         </div>
//       </div>
//       <div className="fill"></div>
//     </label>
//   </div>
// </div>
