import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Centers from "./pages/Centers/Centers";
import Courses from "./pages/Courses/Courses";
import Home from "./pages/Home/Home";
import Contex from "./store/Context";

// export const ThemeContext = createContext();
function App() {
  const mode = localStorage.getItem("mode");
  const lang = localStorage.getItem("language");
  mode === null && localStorage.setItem("mode", "light");
  lang === null && localStorage.setItem("language", "uz");
  return (
    // <ThemeContext.Provider value={{ theme, toggleTheme }}>
    <Contex>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses/:id" element={<Courses />} />
          <Route path="/educations/:id" element={<Centers />} />
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Routes>
      </Layout>
    </Contex>
    // </ThemeContext.Provider>
  );
}

export default App;
