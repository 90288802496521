import React, { useContext } from "react";
import { Select } from "antd";
import "./index.scss";
import { Language } from "../../../services/language";
import { useNavigate } from "react-router-dom";
import { GlobalContex } from "../../../store/Context";
const { Option } = Select;
const FilterData = ({
  data,
  cityId,
  alphabet,
  price,
  mode,
  lifeTime,
  param,
  hash,
}) => {
  const location = localStorage.getItem("location");
  const language = localStorage.getItem("language");
  const { currentLocation, setSearchData } = useContext(GlobalContex);
  const navigation = useNavigate();
  const handleChangeSelect = (value) => {
    cityId(value);
  };
  const handleChangeAlphabetSelect = (value) => {
    alphabet(value);
  };
  const handleChangePrice = (value) => {
    price(value);
  };
  const handleChangeMode = (value) => {
    mode(value);
  };
  const handleChangeLifeTime = (value) => {
    lifeTime(value);
  };
  const handleChangeDirections = (value) => {
    navigation(`/courses/${value}`);
    setSearchData("");
    hash("");
  };
  const newData = [
    {
      title: {
        uz: "Back-End",
        en: "Back-End",
        ru: "Back-End",
      },
      id: "backend",
    },
    {
      title: {
        uz: "Front-End",
        en: "Front-End",
        ru: "Front-End",
      },
      id: "frontend",
    },
    {
      title: {
        uz: "Mobil robototexnika",
        en: "Mobil robotics",
        ru: "Мобильная робототехника",
      },
      id: "robotech",
    },
    {
      title: {
        uz: "Android",
        en: "Android",
        ru: "Android",
      },
      id: "android",
    },
    {
      title: {
        uz: "Dizayn",
        en: "Design",
        ru: "Дизайн",
      },
      id: "design",
    },
    {
      title: {
        uz: "Web-dizayn",
        en: "Web-design",
        ru: "Web-Дизайн",
      },
      id: "webdesign",
    },
    {
      title: {
        uz: "IT-English",
        en: "IT-English",
        ru: "IT-English",
      },
      id: "english",
    },
    {
      title: {
        uz: "ITga kirish",
        en: "Introduction to IT",
        ru: "Введение в IT",
      },
      id: "foundation",
    },
    {
      title: {
        uz: "Motion-design",
        en: "Motion-design",
        ru: "Motion-design",
      },
      id: "motion",
    },
  ];
  const cityData = [
    {
      name: Language("cities")["all"],
      key: "all",
    },
    {
      name: Language("cities")["tashkent"],
      key: "1726",
    },
    {
      name: Language("cities")["tashkent-region"],
      key: "1727",
    },
    {
      name: Language("cities")["bukhara"],
      key: "1706",
    },
    {
      name: Language("cities")["fergana"],
      key: "1730",
    },
    {
      name: Language("cities")["khorezm"],
      key: "1733",
    },
    {
      name: Language("cities")["andijon"],
      key: "1703",
    },
    {
      name: Language("cities")["jizzax"],
      key: "1708",
    },
    {
      name: Language("cities")["kashkadaryo"],
      key: "1710",
    },
    {
      name: Language("cities")["samarkand"],
      key: "1718",
    },
    {
      name: Language("cities")["navoi"],
      key: "1712",
    },
    {
      name: Language("cities")["sirdaryo"],
      key: "1724",
    },
    {
      name: Language("cities")["surxondaryo"],
      key: "1722",
    },
    {
      name: Language("cities")["karakalpok"],
      key: "1735",
    },
  ];
  return (
    <div className="courses-filter-component">
      <Select
        defaultValue={
          currentLocation === null
            ? location
              ? Language("cities")[location]
              : "Город"
            : Language("cities")[currentLocation]
        }
        className="selectInput"
        onChange={handleChangeSelect}
        style={{ width: "251px" }}
      >
        {cityData.map((item) => (
          <Option value={item.key} key={item.key}>
            {item.name}
          </Option>
        ))}
      </Select>

      <Select
        defaultValue={Language("alphabetically")}
        className="selectInput"
        onChange={handleChangeAlphabetSelect}
        style={{ width: "280px" }}
      >
        <Option value={"all"}>{Language("all")}</Option>
        <Option value={"from"}>{Language("alphabetically-item-from")}</Option>
        <Option value={"to"}>{Language("alphabetically-item-before")}</Option>
      </Select>
      <Select
        defaultValue={Language("price")}
        className="selectInput"
        onChange={handleChangePrice}
        style={{ width: "210px" }}
      >
        <Option value="null">{Language("all")}</Option>
        <Option value="max">{Language("max")}</Option>
        <Option value="min">{Language("min")}</Option>
      </Select>
      <Select
        defaultValue={Language("form")}
        className="selectInput"
        onChange={handleChangeMode}
        style={{ width: "174px" }}
      >
        <Option value="null">{Language("all")}</Option>
        <Option value="online">{Language("educationForm")["online"]}</Option>
        <Option value="offline">{Language("educationForm")["offline"]}</Option>
      </Select>
      <Select
        defaultValue={Language("duration")}
        className="selectInput"
        onChange={handleChangeLifeTime}
        style={{ width: "300px" }}
      >
        <Option value="null">{Language("all")}</Option>
        <Option value="3">{Language("months")}</Option>
        <Option value="3-6">3-6 {Language("month")}</Option>
        <Option value="6-12">6-12 {Language("month")}</Option>
      </Select>
      <Select
        value={param === "all" ? Language("directions") : param}
        className="selectInput"
        onChange={handleChangeDirections}
        style={{ width: "243px" }}
      >
        <Option value="all">{Language("all")}</Option>
        {newData?.map((item, index) => (
          <Option value={item.id} key={index}>
            {item.title[language]}
          </Option>
        ))}
      </Select>
      <Select
        defaultValue={Language("rating")}
        className="selectInput"
        // onChange={handleChangeDirections}
        style={{ width: "243px" }}
      >
        <Option value="null">{Language("all")}</Option>
        <Option value="min">1-5</Option>
        <Option value="max">5-1</Option>
      </Select>
    </div>
  );
};

export default FilterData;
