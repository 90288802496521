export const soato = (location) => {
  switch (location) {
    case "tashkent":
      return "1726";
    case "tashkent-region":
      return "1727";
    case "bukhara":
      return "1706";
    case "fergana":
      return "1730";
    case "khorezm":
      return "1733";
    case "andijon":
      return "1703";
    case "jizzax":
      return "1708";
    case "kashkadaryo":
      return "1710";
    case "samarkand":
      return "1718";
    case "navoi":
      return "1712";
    case "sirdaryo":
      return "1724";
    case "surxondaryo":
      return "1722";
    case "karakalpok":
      return "1735";
    default:
      break;
  }
};
