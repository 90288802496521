import React, { useContext } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Modal as AntModal } from "antd";
import { Language } from "../../services/language";
import { AiOutlineClose } from "react-icons/ai";
import "./index.scss";
import { GlobalContex } from "../../store/Context";

const setting = {
  visible: true,
  centered: true,
  closable: false,
  footer: null,
  mask: false,
};
const Modal = ({ setOpen }) => {
  const location = localStorage.getItem("location");
  const { theme } = useContext(GlobalContex);
  const city = require("../../assets/locale/map.json");
  const onSubmit = (e) => {
    localStorage.setItem("location", e.key);
    window.location.reload();
  };
  const cityData = [
    {
      name: Language("cities")["tashkent"],
      key: "tashkent",
    },
    {
      name: Language("cities")["tashkent-region"],
      key: "tashkent-region",
    },
    {
      name: Language("cities")["bukhara"],
      key: "bukhara",
    },
    {
      name: Language("cities")["fergana"],
      key: "fergana",
    },
    {
      name: Language("cities")["khorezm"],
      key: "khorezm",
    },
    {
      name: Language("cities")["andijon"],
      key: "andijon",
    },
    {
      name: Language("cities")["jizzax"],
      key: "jizzax",
    },
    {
      name: Language("cities")["kashkadaryo"],
      key: "kashkadaryo",
    },
    {
      name: Language("cities")["samarkand"],
      key: "samarkand",
    },
    {
      name: Language("cities")["navoi"],
      key: "navoi",
    },
    {
      name: Language("cities")["sirdaryo"],
      key: "sirdaryo",
    },
    {
      name: Language("cities")["surxondaryo"],
      key: "surxondaryo",
    },
    {
      name: Language("cities")["karakalpok"],
      key: "karakalpok",
    },
  ];
  const userMenu = (
    <Menu className={theme === "dark" ? "modal-menu-dark" : "modal-menu-light"}>
      {cityData.map((item) => (
        <Menu.Item
          key={item.key}
          className="menuItem"
          onClick={(e) => onSubmit(e)}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className={theme === "dark" ? "modal-mask-dark" : "modal-mask-light"}>
      <AntModal
        {...setting}
        className={
          theme === "dark" ? "modal-container-dark" : "modal-container-light"
        }
      >
        <div className="modal-content">
          <h6>{Language("located")}</h6>
          <p>{Language("your-city")}</p>
          <Dropdown
            overlay={userMenu}
            trigger={["click"]}
            className={"modal-dropwdown"}
          >
            <Space>
              {location
                ? Language("cities")[location]
                : Language("choose-city")}
              <DownOutlined />
            </Space>
          </Dropdown>
          {location !== null && (
            <AiOutlineClose
              className="close"
              size={30}
              onClick={() => setOpen((prev) => !prev)}
            />
          )}
        </div>
      </AntModal>
    </div>
  );
};

export default Modal;
