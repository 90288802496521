export const dataUniver = [
  {
    background:
      "https://avatars.mds.yandex.net/get-altay/5235198/2a0000017c1239431e6b2c2b6bcb88554da2/XXL_height",
    duration: {
      en: "Duration: 3 years",
      ru: "Продолжительность: 3 года",
      uz: "Davomiyligi: 3 Yil",
    },
    id: 1,
    link: {
      en: "https://itpu.uz/en",
      ru: "https://itpu.uz/ru",
      uz: "https://itpu.uz/",
    },
    linkName: "itpu.uz",
    logo: "https://eduadmin.it-park.uz//storage/images/universities/normal/s170uI0GmMuptuNzWS61MoIcaye1LUzCrw6ErCF9.png",
    mode: {
      en: "Online",
      ru: "Онлайн",
      uz: "Onlayn",
    },
    year: true,
    title: {
      en: "IT-PARK University",
      ru: "IT-PARK University",
      uz: "IT-PARK University",
    },
    tuition_free: {
      en: "Tuition fee: 9.000.000 UZS",
      ru: "Цена: 9.000.000 сум",
      uz: "Narxi: 9.000.000 so'm",
    },
    incoming: "1000",
    graduates: "500",
    locationName: "It-Park University",
    location:
      "https://www.google.com/maps/place/IT+Park/@41.3426119,69.3378238,15z/data=!4m5!3m4!1s0x0:0x676a59b4e6bf3c7e!8m2!3d41.3426119!4d69.3378238",
  },
  {
    background:
      "https://upload.wikimedia.org/wikipedia/commons/c/cf/Tashkent_University_of_Information_Technologies.jpg",
    duration: {
      en: "Duration: 4 years",
      ru: "Продолжительность: 4 года",
      uz: "Davomiyligi: 4 Yil",
    },
    id: 2,
    link: {
      en: "https://tuit.uz/site/index",
      ru: "https://tuit.uz/ru/site/index",
      uz: "https://tuit.uz/en/site/index",
    },
    linkName: "tuit.uz",
    logo: "https://eduadmin.it-park.uz//storage/images/universities/normal/HadvV6fLXENyxhjTrpWFocjsFsadMVhlzXwM5KqD.png",
    mode: {
      en: "Offline",
      ru: "Оффлайн",
      uz: "Oflayn",
    },
    title: {
      en: "TITU",
      ru: "ТУИТ",
      uz: "TATU",
    },
    tuition_free: {
      en: "Tuition fee: 9.000.000 UZS",
      ru: "Цена: 9.000.000 сум",
      uz: "Narxi: 9.000.000 so'm",
    },
    incoming: "5933",
    graduates: "3860",
    locationName: "It-Park University",
    location:
      "https://www.google.com/maps/place/%D0%A2%D0%B0%D1%88%D0%BA%D0%B5%D0%BD%D1%82%D1%81%D0%BA%D0%B8%D0%B9+%D0%A3%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82+%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85+%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B9:/@41.3409302,69.2841488,17z/data=!3m1!4b1!4m6!3m5!1s0x38ae8b534175ed31:0x52a8f9d9414a2ad8!8m2!3d41.3409302!4d69.2867291!16s%2Fm%2F02x8vhy?hl=ru-KG",
  },
  {
    background:
      "https://amity.uz/wp-content/uploads/2021/02/Campus-Tour-Right.jpg",
    duration: {
      en: "Duration: 4 years",
      ru: "Продолжительность: 4 года",
      uz: "Davomiyligi: 4 Yil",
    },
    id: 3,
    link: {
      en: "https://amity.uz/",
      ru: "https://amity.uz/ru",
      uz: "https://amity.uz/uzbek",
    },
    linkName: "amity.uz",
    logo: "https://eduadmin.it-park.uz//storage/images/universities/normal/Kq2DSWe6nZLLZeyOZiuxALOageeDY6nYf1XZwtha.png",
    mode: {
      en: "Offline",
      ru: "Оффлайн",
      uz: "Oflayn",
    },
    title: {
      en: "Amity University",
      ru: "Университет Амити",
      uz: "Amity Universiteti",
    },
    tuition_free: {
      en: "Tuition fee: 33.000.000 UZS",
      ru: "Цена: 33.000.000 сум",
      uz: "Narxi: 33.000.000 so'm",
    },
    incoming: "200",
    graduates: "120",
    location:
      "https://www.google.com/maps/place/Amity+University+Tashkent/@41.3324611,69.2618559,17z/data=!3m1!4b1!4m6!3m5!1s0x38ae8b6cbd7e49a1:0xf23c3817c486d743!8m2!3d41.3324611!4d69.2644362!16s%2Fg%2F11j95s3rcw?hl=ru-KG",
  },
  {
    background: "https://inha.uz/wp-content/uploads/2021/06/building1.jpeg",
    duration: {
      en: "Duration: 4 years",
      ru: "Продолжительность: 4 года",
      uz: "Davomiyligi: 4 Yil",
    },
    id: 4,
    link: "",
    link: {
      en: "https://inha.uz",
      ru: "https://inha.uz/ru/glavnaya/",
      uz: "https://inha.uz/uz/asosiy/",
    },
    linkName: "inha.uz",
    logo: "https://inha.uz/wp-content/uploads/2022/01/emblem.svg",
    mode: {
      en: "Offline",
      ru: "Оффлайн",
      uz: "Oflayn",
    },
    title: {
      en: "INHA University",
      ru: "Университет ИНХА",
      uz: "INHA Universiteti",
    },
    tuition_free: {
      en: "Tuition fee: 33.000.000 UZS",
      ru: "Цена: 33.000.000 сум",
      uz: "Narxi: 33.000.000 so'm",
    },
    incoming: "420",
    graduates: "293",
    location:
      "https://www.google.com/maps/place/%D0%A3%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82+%D0%98%D0%BD%D1%85%D0%B0/@41.3385249,69.3296644,17z/data=!3m1!4b1!4m6!3m5!1s0x38aef48a8ed4d0e9:0x3772abeffc72e7b8!8m2!3d41.338525!4d69.33453!16s%2Fm%2F012vv0__?hl=ru-KG",
  },
  {
    background:
      "https://cdn.tiue.uz/2022/10/14/04/00/vGTrPkWxWfICHXQxmzidWypVJuO1hqgs_normal.png",
    duration: {
      en: "Duration: 4 years",
      ru: "Продолжительность: 4 года",
      uz: "Davomiyligi: 4 Yil",
    },
    id: 5,
    link: {
      en: "https://tiue.uz/en",
      ru: "https://tiue.uz/ru",
      uz: "https://tiue.uz/uz",
    },
    linkName: "tiue.uz",
    logo: "https://tiue.uz/assets/images/logo-svg.svg",
    mode: {
      en: "Offline",
      ru: "Оффлайн",
      uz: "Oflayn",
    },
    title: {
      en: "TIUE",
      ru: "ТМУО",
      uz: "TXTU",
    },
    tuition_free: {
      en: "Tuition fee: 33.000.000 UZS",
      ru: "Цена: 33.000.000 сум",
      uz: "Narxi: 33.000.000 so'm",
    },
    incoming: "1000",
    graduates: "450",
    location:
      "https://www.google.com/maps/place/TIUE+%22Tashkent+International+University+of+Education%22/@41.2913421,69.3603648,17z/data=!3m1!4b1!4m6!3m5!1s0x38aef58639d3ee37:0xee976e78b366b5e8!8m2!3d41.2913422!4d69.3652304!16s%2Fg%2F11p5tfrzht?hl=ru-KG",
  },
  {
    background:
      "https://www.gazeta.uz/media/img/2021/07/81ruCl16262611551980_l.jpg",
    duration: {
      en: "Duration: 4 years",
      ru: "Продолжительность: 4 года",
      uz: "Davomiyligi: 4 Yil",
    },
    id: 6,
    link: {
      en: "https://newuu.uz/en/about/university",
      ru: "https://newuu.uz/ru/about/university",
      uz: "https://newuu.uz/uz/about/university",
    },
    linkName: "newuu.uz",
    logo: "https://www.timeshighereducation.com/sites/default/files/styles/medium/public/nuu_logo.png?itok=LEuSDr_z",
    mode: {
      en: "Offline",
      ru: "Оффлайн",
      uz: "Oflayn",
    },
    title: {
      en: "New Uzbekistan university",
      ru: "Университет Новый Узбекистан",
      uz: "Yangi O‘zbekiston universiteti.",
    },
    tuition_free: {
      en: "Tuition fee: 33.000.000 UZS",
      ru: "Цена: 33.000.000 сум",
      uz: "Narxi: 33.000.000 so'm",
    },
    incoming: "700",
    graduates: "200",
    location:
      "https://www.google.com/maps/place/New+Uzbekistan+University/@41.3371092,69.2216634,13z/data=!4m10!1m2!2m1!1z0YPQvdC40LLQtdGA0YHQuNGC0LXRgiDQvdC-0LLRi9C5INGD0LfQsdC10LrQuNGB0YLQsNC9!3m6!1s0x38aef51b50c0eda7:0x1ef4e0e3a79a0726!8m2!3d41.3238612!4d69.3201704!15sCjbRg9C90LjQstC10YDRgdC40YLQtdGCINC90L7QstGL0Lkg0YPQt9Cx0LXQutC40YHRgtCw0L0iA4gBAZIBCnVuaXZlcnNpdHngAQA!16s%2Fg%2F11pv0fm547?hl=ru-KG",
  },
];
