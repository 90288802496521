import { Drawer } from "antd";
import React, { useContext, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Language } from "../../services/language";
import { GlobalContex } from "../../store/Context";
import { Button, Input, Space } from "antd";
import style from "./index.module.scss";
const SearchInput = () => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { setSearchData } = useContext(GlobalContex);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setInputValue("");
  };
  const onClick = () => {
    setSearchData(inputValue);
    navigate(`courses/all`);
    setOpen(false);
    setInputValue("");
  };
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      onClick();
      return (window.location.href = "#filter");
    }
  };
  return (
    <div className={style.drawerContainer}>
      <RiSearchLine
        onClick={showDrawer}
        className={style.drawerSearchIcon}
        size={20}
      />
      <Drawer
        placement={"top"}
        closable={false}
        onClose={onClose}
        open={open}
        className={style.drawer}
      >
        <div className={`${style.searchInput} wrapper`}>
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Input
              onKeyDown={(e) => onKeyDownHandler(e)}
              className={style.input}
              placeholder={Language("search")}
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
            />
            <Button type="primary" onClick={onClick}>
              {" "}
              {Language("search")}
            </Button>
          </Space.Compact>
          {/* <input
            type={"search"}
            onKeyDown={(e) => onKeyDownHandler(e)}
            className={style.input}
            placeholder={Language("search")}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          />
          <AiOutlineClose onClick={onClose} />
          <button className={style.search}>
            <a href="#filter" onClick={onClick}>
              {Language("search")}
            </a>
          </button> */}
        </div>
      </Drawer>
    </div>
  );
};

export default SearchInput;
