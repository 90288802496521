import {
  Android,
  Bezier,
  Book,
  Code,
  Hierarchy,
  Layer,
  Mobile,
  Pen,
  Set,
  Web,
} from "../../constants/Icons";
import { Language } from "../../services/language";
export const data = [
  {
    title: "Back-End",
    subtitle: Language("cards")["backend"],
    icon: <Hierarchy />,
    link: "backend",
    fontSize: "46px"
  },
  {
    title: "Front-End",
    subtitle: Language("cards")["frontend"],
    icon: <Code />,
    link: "frontend",
    fontSize: "46px"
  },
  {
    title: Language("mobilerobotics"),
    subtitle: Language("cards")["mobile-robotics"],
    icon: <Set />,
    link: "robotech",
    fontSize: "37px"
  },
  {
    title: Language("android"),
    subtitle: Language("cards")["android"],
    icon: <Android />,
    link: "android",
    fontSize: "46px"
  },
  {
    title: Language("design"),
    subtitle: Language("cards")["design"],
    icon: <Pen />,
    link: "design",
    fontSize: "46px"
  },
  {
    title: Language("web-design"),
    subtitle: Language("cards")["web-design"],
    icon: <Web />,
    link: "webdesign",
    fontSize: "46px"
  },
  {
    title: Language("foundation"),
    subtitle: Language("cards")["foundation"],
    icon: <Layer />,
    link: "foundation",
    fontSize: "37px"
  },
  {
    title: "it-english",
    subtitle: Language("cards")["it-english"],
    icon: <Book />,
    link: "english",
    fontSize: "46px"
  },
  {
    title: "motion design",
    subtitle: Language("cards")["motion-design"],
    icon: <Bezier />,
    link: "motion",
    fontSize: "46px"
  },
];
