export const matchFunc = (param) => {
  if (param) {
    const gg = param?.match(/"(.*?)"/);
    if (gg) {
      return gg[1];
    }
    return param;
  }
  return param;
};
