import React from "react";


import { Language } from "../../services/language";
import "./index.scss";
import { OurIcon1, OurIcon2 } from "../../constants/Icons";
const OurProjects = () => {
  const lang = localStorage.getItem("language");
  return (
    <div className="our-projects wrapper">
      <h6>{Language("useful_links")}</h6>
      <div className="our-project-container">
        <a
          href={`https://it-istedod.uz/index-${lang === "en" ? "ru" : lang}`}
          target={"_blank"}
          rel="noreferrer"
          className="our-item"
        >
          <h4 style={{fontSize: Language("sertificatTitle").length > 30 && "35px"}}>{Language("sertificatTitle")}</h4>
          <p>{Language("sertificat-title")}</p>
          <OurIcon2 />
        </a>
        <a
          href={`https://uzbekcoders.uz/${lang === "en" ? "ru" : lang}/main`}
          target={"_blank"}
          rel="noreferrer"
          className="our-item"
        >
          <h4>{Language("omucTitle")}</h4>
          <p className="title">{Language("omuc-title")}</p>
          <OurIcon1 />
        </a>
      </div>
    </div>
  );
};

export default OurProjects;
