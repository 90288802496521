const dataEN = {
  tashkent: "Tashkent city",
  "tashkent-region": "Tashkent region",
  bukhara: "Bukhara",
  fergana: "Fergana",
  namangan: "Namangan",
  khorezm: "Khorezm",
  andijon: "Andijan",
  jizzax: "Jizzakh",
  kashkadaryo: "Kashkadarya",
  samarkand: "Samarkand",
  navoi: "Navoi",
  sirdaryo: "Sirdarya",
  surxondaryo: "Surkhandarya",
  karakalpok: "R.Karakalpakstan",
};
const dataRU = {
  tashkent: "Ташкент",
  "tashkent-region": "Ташкентская область",
  bukhara: "Бухара",
  fergana: "Фергана",
  namangan: "Наманган",
  khorezm: "Хорезм",
  andijon: "Андижан",
  jizzax: "Джизак",
  kashkadaryo: "Кашкадарья",
  samarkand: "Самарканд",
  navoi: "Навои",
  sirdaryo: "Сырдарья",
  surxondaryo: "Сурхандарья",
  karakalpok: "Р.Каракалпакстан",
};
const dataUZ = {
  tashkent: "Toshkent",
  "tashkent-region": "Toshkent viloyati",
  bukhara: "Buxoro",
  fergana: "Farg'ona",
  namangan: "Namangan",
  khorezm: "Xorazm",
  andijon: "Andijon",
  jizzax: "Jizzax",
  kashkadaryo: "Qashqadaryo",
  samarkand: "Samarqand",
  navoi: "Navoiy",
  sirdaryo: "Sirdaryo",
  surxondaryo: "Surxondaryo",
  karakalpok: "Qoraqalpog‘iston",
};
export const maskLocation = (param) => {
  const lan = localStorage.getItem("language");
  if (lan === "ru") {
    return dataRU[param];
  }
  if (lan === "uz") {
    return dataUZ[param];
  }
  if (lan === "en") {
    return dataEN[param];
  }
};
