import React from "react";
import { MainBg } from "../../constants/Icons";
import "./index.scss";
const Animation = () => {
  return (
    <div className="main-background">
      <MainBg />
    </div>
  );
};

export default Animation;
