import EN from "../assets/locale/translationEn.json";
import RU from "../assets/locale/translationRu.json";
import UZ from "../assets/locale/translationUz.json";
export function Language(key) {
  let lang = localStorage.getItem("language");
  let data = null;

  switch (lang) {
    case "uz":
      data = UZ;
      break;

    case "ru":
      data = RU;
      break;

    case "en":
      data = EN;
      break;

    default:
      break;
  }

  if (data?.hasOwnProperty(key)) {
    return data[key];
  }

  return key;
}
