import React from "react";
import { useContext } from "react";
import { GlobalContex } from "../../store/Context";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Layout = ({ children }) => {
  const { theme } = useContext(GlobalContex);
  return (
    <div id={theme}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
