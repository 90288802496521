import { Button, Drawer } from "antd";
import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CgMenuRightAlt } from "react-icons/cg";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Language } from "../../services/language";
import { maskLocation } from "../../services/maskLocation";
import DropDown from "../DropDown/DropDown";
import { ReactComponent as Dark } from "../../assets/icons/dark-logo.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";

import style from "./index.module.scss";
import Modal from "../Modal/Modal";
import { GlobalContex } from "../../store/Context";
const Menu2 = () => {
  const location = localStorage.getItem("location");
  const [open, setOpen] = useState(false);
  const [draw, setDraw] = useState(false);
  const navigate = useNavigate();
  const loc = useLocation();
  const { theme, toggleTheme } = useContext(GlobalContex);

  const showDrawer = () => {
    setDraw(true);
  };
  const onClose = () => {
    setDraw(false);
  };
  const onSubmit = (e) => {
    let id = e.target.id;
    if (loc.pathname === "/") {
      setDraw(false);
      return (window.location.href = id);
    } else {
      setDraw(false);
      navigate("/");
      setTimeout(() => {
        return (window.location.href = id);
      }, 0);
    }
  };
  const onOpenModal = () => {
    // setDraw(false);
    setOpen(true);
  };
  const onMain = () => {
    setDraw(false);
    navigate("/");
  };
  return (
    <>
      <CgMenuRightAlt size={30} onClick={showDrawer} />
      <Drawer
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={draw}
        key={"right"}
        className={style.menuDrawer}
      >
        <div className={style.menuActions}>
          <div className="flex-center-gap">
            <DropDown />
            {location && (
              <div className="flex-center" onClick={() => onOpenModal()}>
                <HiOutlineLocationMarker size={20} className="location" />
                <p>{maskLocation(location)}</p>
              </div>
            )}
            {/* <SearchInput /> */}
          </div>
          <div onClick={onClose}>
            <AiOutlineClose size={30} />
          </div>
        </div>
        <div className={style.menuNavlink}>
          <ul>
            <li onClick={onSubmit} id="#it-coures">
              {Language("courses")}
            </li>
            <li onClick={onSubmit} id="#region-contacts">
              {Language("contacts_by_region")}
            </li>
            <li onClick={onSubmit} id="#useful-links">
              {Language("useful_links")}
            </li>
            <li onClick={onSubmit} id="#faq">
              F.A.Q.
            </li>
          </ul>
          <div onClick={onMain}>{theme === "light" ? <Dark /> : <Logo />}</div>
        </div>
        {open && <Modal setOpen={setOpen} />}
      </Drawer>
    </>
  );
};

export default Menu2;
